// 创建路由对象
import VueRouter from "vue-router";
// import { useUserStore } from "../../public/stores/modules/user.js";
const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/home",
      // component: () => import("./components/LoginPage.vue"),
    },
    {
      path: "/login",
      component: () => import("@/components/LoginPage.vue"),
    },
    {
      path: "/markdownEditor",
      component: () => import("@/views/MarkdownEditor.vue"),
    },
    {
      path: "/problem/:problemId",
      component: () => import("@/components/problem/AnsweringInfo.vue"),
    },
    {
      path: "/problem/:contestId/:problemId",
      component: () => import("@/components/contests/ContestProblemDetail.vue"),
    },
    {
      path: "/home",
      redirect: "/home/indexInfo",

      component: () => import("@/components/Home.vue"),
      children: [
        {
          path: "search/:searchContent",
          component: () => import("@/views/SearchInfo.vue"),
        },
        {
          path: "contestDetail/:contestId",
          component: () => import("@/views/ContestDetail.vue"),
        },
        {
          path: "contest",
          component: () => import("@/views/ContestInfo.vue"),
        },
        {
          path: "awardInfo",
          component: () => import("@/views/AwardInfo.vue"),
        },
        {
          path: "stuInfo/:userId",
          component: () => import("@/views/UserInfo.vue"),
        },
        {
          path: "memberInfo",
          component: () => import("@/views/memberInfo.vue"),
        },
        {
          path: "problemInfo",
          component: () => import("@/views/ProblemInfo.vue"),
        },

        {
          path: "indexInfo",
          component: () => import("@/views/IndexInfo.vue"),
        },
        {
          path: "articleInfo/:articleId",
          name: "articleInfo",
          component: () => import("@/views/ArticleInfo.vue"),
        },

        {
          path: "discussInfo",
          component: () => import("@/views/DiscussInfo.vue"),
        },
        {
          path: "trainingInfo",
          component: () => import("@/views/TrainingInfo.vue"),
        },
        {
          path: "training/:trainingId",
          component: () => import("@/views/TrainingPersonalInfo.vue"),
        },
      ],
    },
  ],
});

// router.beforeEach((to, from, next) => {
//   // if (to.path === "/login") {
//   //   next();
//   // }
//   //  else {
//   //   const userStore = useUserStore();
//   //   const token = userStore.getToken;
//   //   if (token === "null" || token === "" || token === undefined) {
//   //     next("/home");
//   //   } else {
//   //     next();
//   //   }
//   // }
// });

export default router;
